import React, { useState } from 'react';
import styled from 'styled-components';
import github from '../../assets/images/github.png';
import bash1 from '../../assets/images/bash/bash1.png';
import bash2 from '../../assets/images/bash/bash2.png';
import SPA from '../../assets/images/bash/SPA.png';
import MPA from '../../assets/images/bash/MPA.png';

const Paragraph = styled.p`
  font-size: 1.1em;
  margin: 20px;
  line-height: 1.8;
  text-indent: 30px;
`;  
const Heading = styled.div`
  font-family: 'Bruno Ace Sc', cursive;
  font-size: 1.1em;
  font-weight: 900;
  margin-left: 20px;
  margin-top: 10px;
`;
const Link = styled.img`
  float: right;
  width: 48px;
  margin: 5px;
  border-radius: 50%;
  box-shadow: 5px 5px 5px #444;
  background-image: radial-gradient(#d44e00, #d44e00, #fff,#fff);
  &:hover {
    background-image: none; 
    box-shadow: 5px 5px 5px #d44e00;       
  }
`;
const Image = styled.img`
      width: 300px;
      heigth: 200px;
      margin: 10px;
      cursor: zoom-in;
      border-radius: 5px;
      box-shadow: 10px 10px 5px #444; 
      ${(props) =>
        props.expanded &&
        `transform: scale(2);
        cursor: zoom-out;
        `
      }
      &:hover {
        box-shadow: 10px 10px 5px #d44e00;        
      }
      @media(max-width: 600px) {
        width: 60%;
        heigth: 150px;
        margin: auto;
      }
    `;
    const ImageContainer = styled.div`
      display: flex;
      width: 100%;
      padding: 10px;
      flex-wrap: wrap;
      justify-content: center; 
    `;
const BashScript = () => {

  const [expandedImage, setExpandedImage] = useState(null);    
  
    const handleImageClick = (imageId) => {
      if (expandedImage === imageId) {
        setExpandedImage(null);
      } else {
        setExpandedImage(imageId);
      }
    };
  return (
    <div>
      <Heading>About The Project</Heading>
      <Paragraph>
        Presenting a versatile bash script that empowers users to effortlessly generate either a single-page or multipage website template. This script streamlines the process of setting up a well-structured foundation for web development projects.
      </Paragraph>
      <ImageContainer>
        <Image 
          src={bash1} 
          alt='image'
          expanded={expandedImage === bash1}
          onClick={() => handleImageClick(bash1)}
        />
        <Image 
          src={SPA} 
          alt='SPA'
          expanded={expandedImage === SPA}
          onClick={() => handleImageClick(SPA)}
        />
        <Image 
          src={bash2} 
          alt='bash2'
          expanded={expandedImage === bash2}
          onClick={() => handleImageClick(bash2)}
        />
        <Image 
          src={MPA} 
          alt='MPA'
          expanded={expandedImage === MPA}
          onClick={() => handleImageClick(MPA)}
        />
      </ImageContainer>
      <a href="https://github.com/jksapps246/linux_terminal_config" target="_blank" rel="noreferrer">
        <Link src={github} alt="Github Icon" />
      </a>
    </div>
  );
}

export default BashScript;