
import React from 'react';
import styled from 'styled-components';
import linkedin from '../assets/images/linkedin-64.png';
import github from '../assets/images/github.png';

const SocialContainer = styled.div`
  display: flex;
  //justify-content: flex-end;
`;

const SocialIcon = styled.img`
  width: 48px;
  margin: 0 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const Social = ({ style }) => {
  return (
    <SocialContainer style={style}>
      <a href="https://linkedin.com/in/john-king01" target="_blank" rel="noreferrer">
        <SocialIcon src={linkedin} alt="Linkedin Icon" />
      </a>
      <a href="https://github.com/jksapps246" target="_blank" rel="noreferrer">
        <SocialIcon src={github} alt="Github Icon" />
      </a>
    </SocialContainer>
  );
};

export default Social;
