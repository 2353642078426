import React from 'react';
import styled from 'styled-components';
import Social from '../components/Social';

const FootContainer = styled.div`
  width: 100%;
  z-index: 2;
  height: 120px;
  padding: 20px 0;
  background-image: linear-gradient(to top, #d44e00, transparent);
  text-align: center;
  position: relative;
`; 
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`; 
const Paragraph = styled.p`
  color: #fff;
  text-align: center;
  position: relative;
`;
const SocialContainer = styled.div`
  flex: 1;;
  display: flex;
  justify-content: center;
  padding: 10px 0;
`; 
const Foot = () => {

  const year = new Date().getFullYear();

  return (
    <FootContainer>
        <Container>
          <Paragraph>Copyright 2023  - {year} John King's Portfolio</Paragraph> 
          <SocialContainer>
            <Social />
          </SocialContainer>
        </Container>
    </FootContainer>
  );
};

export default Foot;