import React, { useState } from 'react';
import styled from 'styled-components';
import github from '../../assets/images/github.png';
// IOS App
import wireframe1 from '../../assets/images/icollect/wireframe1.png';
import wireframe2 from '../../assets/images/icollect/wireframe2.png';
import wireframe3 from '../../assets/images/icollect/wireframe3.png';
import wireframe4 from '../../assets/images/icollect/wireframe4.png';
import wireframe5 from '../../assets/images/icollect/wireframe5.png';
import wireframe6 from '../../assets/images/icollect/wireframe6.png';
import wireframe7 from '../../assets/images/icollect/wireframe7.png';
import wireframe8 from '../../assets/images/icollect/wireframe8.png';
import solution1 from '../../assets/images/icollect/solution1.png';
import solution2 from '../../assets/images/icollect/solution2.png';
import solution3 from '../../assets/images/icollect/solution3.png';
import solution4 from '../../assets/images/icollect/solution4.png';
import solution5 from '../../assets/images/icollect/solution5.png';
import solution6 from '../../assets/images/icollect/solution6.png';
import solution7 from '../../assets/images/icollect/solution7.png';


const ICollect = () => {

    const Heading = styled.div`
        font-family: 'Bruno Ace Sc', cursive;
        font-size: 1.1em;
        font-weight: 900;
        margin-left: 20px;
        margin-top: 10px;
    `;

    const Image = styled.img`
      width: 100px;
      weigth: 200px;
      margin: 10px;
      cursor: zoom-in;
      border-radius: 5px;
      box-shadow: 10px 10px 5px #444; 
      ${(props) =>
        props.expanded &&
        `transform: scale(2);
        cursor: zoom-out;
        `
      }
      &:hover {
        box-shadow: 10px 10px 5px #d44e00;        
      }
      @media(max-width: 600px) {
        width: 80px;
        weigth: 150px;
        
      }
    `;
    const ImageContainer = styled.div`
      display: flex;
      width: 100%;
      padding: 10px;
      flex-wrap: wrap;
      justify-content: center;   
    `;
    const Paragraph = styled.p`
      font-size: 1.1em;
      margin: 20px;
      line-height: 1.8;
      text-indent: 30px;
    `;    
    const Link = styled.img`
      float: right;
      width: 48px;
      margin: 5px;
      border-radius: 50%;
      box-shadow: 5px 5px 5px #444;
      background-image: radial-gradient(#d44e00, #d44e00, #fff,#fff);
      &:hover {
        background-image: none; 
        box-shadow: 5px 5px 5px #d44e00;       
      }
    `;
    const solutions = [
      {
        id: 0,
        imgSrc: solution1,
        imgName: 'solution 1'
      },
      {
        id: 1,
        imgSrc: solution2,
        imgName: 'solution 2'
      },
      {
        id: 2,
        imgSrc: solution3,
        imgName: 'solution 3'
      },
      {
        id: 3,
        imgSrc: solution4,
        imgName: 'solution 4'
      },
      {
        id: 4,
        imgSrc: solution5,
        imgName: 'solution 5'
      },
      {
        id: 5,
        imgSrc: solution6,
        imgName: 'solution 6'
      },
      {
        id: 6,
        imgSrc: solution7,
        imgName: 'solution 7'
      }
  ]; 
  const wireframes = [
    {
      id: 0,
      imgSrc: wireframe1,
      imgName: 'wireframe 1'
    },
    {
      id: 1,
      imgSrc: wireframe2,
      imgName: 'wireframe 2'
    },
    {
      id: 2,
      imgSrc: wireframe3,
      imgName: 'wireframe 3'
    },
    {
      id: 3,
      imgSrc: wireframe4,
      imgName: 'wireframe 4'
    },
    {
      id: 4,
      imgSrc: wireframe5,
      imgName: 'wireframe 5'
    },
    {
      id: 5,
      imgSrc: wireframe6,
      imgName: 'wireframe 6'
    },
    {
      id: 6,
      imgSrc: wireframe7,
      imgName: 'wireframe 7'
    },
    {
      id: 7,
      imgSrc: wireframe8,
      imgName: 'wireframe 8'
    }  
]; 
    const [expandedImage, setExpandedImage] = useState(null);    
  
    const handleImageClick = (imageId) => {
      if (expandedImage === imageId) {
        setExpandedImage(null);
      } else {
        setExpandedImage(imageId);
      }
    };

    return (
        <div>           
          <Heading>About The Project</Heading>
          <Paragraph>With this app, users can conveniently store and share pictures of their cherished collectibles, showcasing their hobbies to others.</Paragraph>
          <Heading>The Problem</Heading>
          <Paragraph>Within a two-week timeframe, develop an app using Swift and Xcode that incorporates at least 16 essential functions, encompassing five distinct screens and two landscape views.</Paragraph>
          <Heading>Prototying & Testing</Heading>
          <Paragraph>I started by designing the wireframe for the app and intensive reseach on mySQL for the local database and pdfViewer to present the collections for sharing.</Paragraph>
          <ImageContainer>
            {wireframes.map((imgS, index) => (
            <Image 
              key={index} 
              src={imgS.imgSrc} 
              alt={imgS.imgName} 
              expanded={expandedImage === imgS.imgSrc}
              onClick={() => handleImageClick(imgS.imgSrc)}
              />
            ))}
          </ImageContainer>
          <Heading>The Solution</Heading>
          <Paragraph>The app initiates with a brief splash screen that displays for a duration of three seconds. Following the splash screen, users are greeted with a welcoming screen that presents the total count of stored collections, accompanied by two prominent buttons. One button facilitates sharing the collection, while the other grants access to adding, viewing, and editing existing collections.</Paragraph>
          <Paragraph>Every collection comprises an image, a name, and a description. Within each collection, users can explore a list view of all the stored pictures, equipped with search functionality and the ability to add or edit entries. To remove a specific picture, a simple left swipe gesture serves as the delete action.</Paragraph>
          <ImageContainer>
            {solutions.map((imgS) => (
              <Image 
                key={imgS.id} 
                src={imgS.imgSrc} 
                alt={imgS.imgName} 
                expanded={expandedImage === imgS.imgSrc}
                onClick={() => handleImageClick(imgS.imgSrc)}
                />
            ))}
          </ImageContainer>
          <Paragraph>By utilizing the share button, users can generate a PDF file encompassing the selected collection, including all associated pictures. This feature provides the convenience of sharing the collection with contacts via various apps or email.</Paragraph>
          <a href="https://github.com/jksapps246/icollect-2" target="_blank" rel="noreferrer">
            <Link src={github} alt="Github Icon" />
          </a>
        </div>
    );
}
export default ICollect;