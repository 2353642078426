import React from 'react';
import Hero from '../components/Hero';
//import Anchor from '../components/Anchor';
import Sections from '../components/Sections';

const Home = () => {
  return (
    <div>
      {/* <Anchor /> */}
      <Hero />          
      <Sections />
    </div>    
  );
};
  
 export default Home;
  