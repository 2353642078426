import React, { useState } from 'react';
import styled from 'styled-components';
import github from '../../assets/images/github.png';
// IOS App
import wireframe1 from '../../assets/images/mechanicconnect/wireframe1.png';
import wireframeL1 from '../../assets/images/mechanicconnect/wireframeL1.png';
import wireframe2 from '../../assets/images/mechanicconnect/wireframe2.png';
import wireframeL2 from '../../assets/images/mechanicconnect/wireframeL2.png';
import wireframe3 from '../../assets/images/mechanicconnect/wireframe3.png';
import wireframeL3 from '../../assets/images/mechanicconnect/wireframeL3.png';
import wireframe4 from '../../assets/images/mechanicconnect/wireframe4.png';
import wireframeL4 from '../../assets/images/mechanicconnect/wireframeL4.png';
import solution1 from '../../assets/images/mechanicconnect/solution1.png';
import solution2 from '../../assets/images/mechanicconnect/solution2.png';
import solution3 from '../../assets/images/mechanicconnect/solution3.png';
import solution4 from '../../assets/images/mechanicconnect/solution4.png';
import solution5 from '../../assets/images/mechanicconnect/solution5.png';
import solution6 from '../../assets/images/mechanicconnect/solution6.png';
import solution7 from '../../assets/images/mechanicconnect/solution7.png';
import solution8 from '../../assets/images/mechanicconnect/solution8.png';


const MechanicConnect = () => {

    const Heading = styled.div`
        font-family: 'Bruno Ace Sc', cursive;
        font-size: 1.1em;
        font-weight: 900;
        margin-left: 20px;
        margin-top: 10px;
    `;

    const Image = styled.img`
      width: 100px;
      weigth: 200px;
      margin: 10px;
      cursor: zoom-in;
      border-radius: 5px;
      box-shadow: 10px 10px 5px #444; 
      ${(props) =>
        props.expanded &&
        `transform: scale(2);
        cursor: zoom-out;
        `
      }
      &:hover {
        box-shadow: 10px 10px 5px #d44e00;        
      }
      @media(max-width: 600px) {
        width: 80px;
        weigth: 150px;        
      }
    `;
    const ImageContainer = styled.div`
      display: flex;
      width: 100%;
      padding: 10px;
      flex-wrap: wrap;
      justify-content: center;  
    `;
    const Paragraph = styled.p`
      font-size: 1.1em;
      margin: 20px;
      line-height: 1.8;
      text-indent: 30px;
    `;    
    const Link = styled.img`
      float: right;
      width: 48px;
      margin: 5px;
      border-radius: 50%;
      box-shadow: 5px 5px 5px #444;
      background-image: radial-gradient(#d44e00, #d44e00, #fff,#fff);
      &:hover {
        background-image: none; 
        box-shadow: 5px 5px 5px #d44e00;       
      }
    `;
    const solutions = [
      {
        imgSrc: solution1,
        imgName: 'solution 1'
      },
      {
        imgSrc: solution2,
        imgName: 'solution 2'
      },
      {
        imgSrc: solution3,
        imgName: 'solution 3'
      },
      {
        imgSrc: solution4,
        imgName: 'solution 4'
      },
      {
        imgSrc: solution5,
        imgName: 'solution 5'
      },
      {
        imgSrc: solution6,
        imgName: 'solution 6'
      },
      {
        imgSrc: solution7,
        imgName: 'solution 7'
      },
      {
        imgSrc: solution8,
        imgName: 'solution 8'
      }
  ]; 
  const wireframes = [
    {
      imgSrc: wireframe1,
      imgName: 'wireframe 1'
    },
    {
      imgSrc: wireframeL1,
      imgName: 'wireframe Landscape 1'
    },
    {
      imgSrc: wireframe2,
      imgName: 'wireframe 2'
    },
    {
      imgSrc: wireframeL2,
      imgName: 'wireframe Landscape 2'
    },
    {
      imgSrc: wireframe3,
      imgName: 'wireframe 3'
    },
    {
      imgSrc: wireframeL3,
      imgName: 'wireframe Landscape 3'
    },
    {
      imgSrc: wireframe4,
      imgName: 'wireframe 4'
    },
    {
      imgSrc: wireframeL4,
      imgName: 'wireframe Landscape 4'
    }  
]; 
    const [expandedImage, setExpandedImage] = useState(null);    
  
    const handleImageClick = (imageId) => {
      if (expandedImage === imageId) {
        setExpandedImage(null);
      } else {
        setExpandedImage(imageId);
      }
    };

    return (
        <div>           
          <Heading>About The Project</Heading>
          <Paragraph>The primary purpose of this app is to seamlessly connect car owners facing car issues with local mechanics, offering a hassle-free and expedient means to access top-notch service of the highest quality.</Paragraph>
          <Heading>The Problem</Heading>
          <Paragraph>Within a two-week timeframe, develop an app using Android Studio and Java/Kotlin that incorporates at least 16 essential functions, encompassing five distinct screens and two landscape views.</Paragraph>
          <Heading>Prototying & Testing</Heading>
          <Paragraph>I started by designing the wireframe for the app and intensive reseach on mySQL for the local database, google maps to search and find the local mechanic shops.</Paragraph>
          <ImageContainer>
            {wireframes.map((imgS, index) => (
            <Image 
              key={index} 
              src={imgS.imgSrc} 
              alt={imgS.imgName} 
              expanded={expandedImage === imgS.imgSrc}
              onClick={() => handleImageClick(imgS.imgSrc)}
              />
            ))}
          </ImageContainer>
          <Heading>The Solution</Heading>
          <Paragraph>This app caters to both mechanics and customers, providing a seamless platform for their interactions. It commences with a user-friendly login screen, offering options to register on a separate screen. After successful registration, users are prompted to input their relevant information, granting them access to a wide array of available mechanics. The app comprises three tabs: Home, Garage/Client, and Chat, each serving a distinct purpose.</Paragraph>
          <Paragraph>The Garage tab is tailored for clients, serving as a centralized hub to store vital information about their vehicles and the mechanics assigned to handle their specific needs. On the other hand, the Client tab is designed for mechanics, enabling them to conveniently manage and access a list of clients who have sought their assistance. Finally, the Chat tab acts as a dedicated communication channel, facilitating seamless contact between mechanics and clients, fostering effective and efficient collaboration.</Paragraph>
          <ImageContainer>
             {solutions.map((imgS, index) => (
              <Image 
                key={index} 
                src={imgS.imgSrc} 
                alt={imgS.imgName} 
                expanded={expandedImage === imgS.imgSrc}
                onClick={() => handleImageClick(imgS.imgSrc)}
                />
            ))} 
          </ImageContainer>
          <Paragraph>Overall, this app provides a comprehensive solution that enhances the experience for both mechanics and customers, streamlining their interactions and ensuring seamless communication throughout the entire process.</Paragraph>
          <a href="https://github.com/jksapps246/mechanicconnect" target="_blank" rel="noreferrer">
            <Link src={github} alt="Github Icon" />
          </a>
        </div>
    );
}
export default MechanicConnect;