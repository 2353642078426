import React, { useState } from "react";
import styled from 'styled-components';
import AboutMe from '../components/AboutMe';
import Skills from '../components/Skills';
import Projects from '../components/Projects';
import Contact from '../components/Contact';

function Sections() {
  const [activeTab, setActiveTab] = useState("About");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const Container = styled.div`
    width: 100%;
    padding: 50px 10px;
    border-radius: 5px;
  `;
  
  const TabStyles = styled.div`  
    display: flex;
    justify-content: center;
    gap: auto;
    @media(max-width: 600px){
      gap: 1px;
    }
  `;

  const ButtonS = styled.button`
    width: 150px;
    height: 50px;         
    border: none;
    cursor: pointer;
    // font-size: 1.2em;
    font-weight: 700;
    font-family: 'Bruno Ace SC', cursive;    
    text-decoration: none;
    padding: 10px;
    background: transparent;
    transition: background-color 0.3s ease;
    z-index: 4;
      &:hover {
        background: #444;
      }
  `;
  const buttonStyles = {           
    color: '#fff',
  };

  const activeButtonStyles = {
    background: '#d44e00',
    color: '#444"'
  };

  const TabContentStyles = styled.div`
    width: 100%;
    min-height: 40vh;
    background: #fff;
    padding: 50px 20px;
    border-radius: 5px;
`;
  return (
    <Container>
      <TabStyles>
      <ButtonS
          style={activeTab === "About" ? {...buttonStyles, ...activeButtonStyles} : buttonStyles}
          onClick={() => handleTabClick("About")}
        >
          ABOUT
        </ButtonS>
        <ButtonS
          style={activeTab === "Skills" ? {...buttonStyles, ...activeButtonStyles} : buttonStyles}
          onClick={() => handleTabClick("Skills")}
        >
          SKILLS
        </ButtonS>
        <ButtonS
          style={activeTab === "Projects" ? {...buttonStyles, ...activeButtonStyles} : buttonStyles}
          onClick={() => handleTabClick("Projects")}
        >
          PROJECTS
        </ButtonS>
        <ButtonS
          style={activeTab === "Contact" ? {...buttonStyles, ...activeButtonStyles} : buttonStyles}
          onClick={() => handleTabClick("Contact")}
        >
          CONTACT
        </ButtonS>
      </TabStyles>
      <TabContentStyles>
        {activeTab === "About" && (
          <AboutMe />
        )}
        {activeTab === "Skills" && (
          <Skills />
        )}
        {activeTab === "Projects" && (
          <Projects />
        )}
        {activeTab === "Contact" && (
          <Contact />
        )}
      </TabContentStyles>
    </Container>
  );
}

export default Sections;
