import React, { useState } from 'react';
import styled from 'styled-components';
import github from '../../../assets/images/github.png';
import wlink from '../../../assets/images/link.png';
import MJ from '../../../assets/images/websites/moviejest.png';

const Paragraph = styled.p`
  font-size: 1.1em;
  margin: 20px;
  line-height: 1.8;
  text-indent: 30px;
`;  
const Heading = styled.div`
  font-family: 'Bruno Ace Sc', cursive;
  font-size: 1.1em;
  font-weight: 900;
  margin-left: 20px;
  margin-top: 10px;
`;
const Link = styled.img`
  float: right;
  width: 48px;
  margin: 5px;
  border-radius: 50%;
  box-shadow: 5px 5px 5px #444;
  background-image: radial-gradient(#d44e00, #d44e00, #fff,#fff);
  &:hover {
    background-image: none; 
    box-shadow: 5px 5px 5px #d44e00;       
  }
`;
const Image = styled.img`
  width: 50%;
  heigth: 200px;
  margin: 10px;
  cursor: zoom-in;
  border-radius: 5px;
  box-shadow: 10px 10px 5px #444; 
  ${(props) =>
  props.expanded &&
  `transform: scale(1.2);
  cursor: zoom-out;
  `
  }
  &:hover {
  box-shadow: 10px 10px 5px #d44e00;        
  }
  @media(max-width: 600px) {
  width: 60%;
  heigth: 150px;
  margin: auto;
  }
`;
const ImageContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 15px;
    flex-wrap: wrap;
    justify-content: center; 
`;
const MovieJest = () => {

  const [expandedImage, setExpandedImage] = useState(null);      
    const handleImageClick = (imageId) => {
      if (expandedImage === imageId) {
        setExpandedImage(null);
      } else {
        setExpandedImage(imageId);
      }
    };

  return (
    <div>      
      <ImageContainer>
        <Image 
          src={MJ} 
          alt='movie jest'
          expanded={expandedImage === MJ}
          onClick={() => handleImageClick(MJ)}
        />   
      </ImageContainer>
      <Heading>About The Project</Heading>
      <Paragraph>
          Utilizing PHP and APIs sourced from <a href="https://www.themoviedb.org" target="_blank" rel="noreferrer"> www.themoviedb.org </a>, this project culminates in the creation of a dynamic website. The website enriches user experience by providing access to movie trailers and comprehensive information about the cast for each film.
      </Paragraph>         
      <a href="https://github.com/jksapps246/moviejest" target="_blank" rel="noreferrer">
        <Link src={github} alt="Github Icon" />
      </a>
      <a href="https://www.moviejest.ca" target="_blank" rel="noreferrer">
        <Link src={wlink} alt="Link Icon" />
      </a>
    </div>
  );
}

export default MovieJest;