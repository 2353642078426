import styled from 'styled-components';

import MJImg from '../../assets/images/movieJest.png';
import EMImg from '../../assets/images/eMechanic.png';
import ICImg from '../../assets/images/iCollect.png';
import CMImg from '../../assets/images/CaribMart.png';
import DIUImg from '../../assets/images/dailItUp.png';
import MCImg from '../../assets/images/mechanicConnect.png';
import BkgImg from '../../assets/images/background_cover.png';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

`;
const ImageContainer = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 200px;
  height: 200px;
  margin: 10px;
  background-image: url(${BkgImg});
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 600px) {
    justify-content: center;      
    width: 100px;
    height: 150px;
  }
`;
const Image = styled.img`
    width: 100px;
    heigth: 200px;
    @media (max-width: 600px) {
      width: 50px;
      heigth: 100px;
    }
`;
const ImageText = styled.p`
font-family: 'Bruno Ace Sc', cursive;
  color: #444;
  height: 50px;
  font-size: 1em;
  font-weight: 700;
  @media (max-width: 600px) {
    font-size: .8em; 
  }
`;
const imgStyles = [
    {
      id: 0,
      src: MJImg,
      name: 'Movie Jest'
    },
    {
      id: 1,
      src: EMImg,
      name: 'eMechanic'
    },
    {
      id: 2,
      src: ICImg,
      name: 'iCollect'
    },
    {
      id: 3,
      src: CMImg,
      name: 'Carib Mart'
    },
    {
      id: 4,
      src: DIUImg,
      name: 'Dial It Up'
    },
    {
      id: 5,
      src: MCImg,
      name: 'Mechanic Connect'
    }  
];
const Logos = () => {
    return (
        <Container>
          {imgStyles.map((imgS) => (
            <ImageContainer key={imgS.id}>
              <Image src={imgS.src} alt={imgS.name} />
              <ImageText>{imgS.name}</ImageText>
            </ImageContainer>
          ))}   
        </Container>
    );
}

export default Logos;