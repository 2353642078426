import styled from 'styled-components';
import Licon from '../components/Licon';
import LiconImg from '../components/LiconImg';
import LiconText from '../components/LiconText';
import CR from  '../assets/images/crystal_reports.png';


const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  @media(max-width: 600px){
    gap: 1px;
  }
`;
const Heading = styled.h1`
  font-size: 2em;
  font-family: 'Bruno Ace SC', cursive; 
`;

const SkillItems = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;

`;

const Skills = () => {
return (
  <Container>
    <Heading>Languages</Heading>
    <SkillItems>
      <Licon daSource='devicon-android-plain' name='Android' />
      <Licon daSource='devicon-html5-plain' name='HTML5' />
      <Licon daSource='devicon-java-plain' name='Java' />
      <Licon daSource='devicon-javascript-plain' name='JavaScript' />
      <Licon daSource='devicon-csharp-plain' name='C#' />
      <Licon daSource='devicon-microsoftsqlserver-plain' name='SQL' />
      <Licon daSource='devicon-php-plain' name='PHP' />
      <Licon daSource='devicon-cplusplus-plain' name='C++' />
      <Licon daSource='devicon-swift-plain' name='Swift' />
      <Licon daSource='devicon-bash-plain' name='Bash' />
      <Licon daSource='devicon-dotnetcore-plain' name='ASP.NET Core' />
      <Licon daSource='devicon-nodejs-plain' name='NodeJS' />
      <Licon daSource='devicon-react-original' name='React' />   
      <LiconText name='JSON' />
      <LiconText name='XML' />
    </SkillItems>
    <Heading>Development Tools</Heading>
    <SkillItems>
      <Licon daSource='devicon-xcode-plain' name='XCode' />
      <Licon daSource='devicon-androidstudio-plain' name='Android Studio' />         
      <Licon daSource='devicon-vscode-plain' name='VSCode' />
      <Licon daSource='devicon-blender-original' name='Blender' />
      <Licon daSource='devicon-wordpress-plain' name='WordPress' />
      <Licon daSource='devicon-illustrator-plain' name='Illustrator' />
      <Licon daSource='devicon-photoshop-plain' name='Photoshop' />
      <Licon daSource='devicon-inkscape-plain' name='Inkscape' />
      <Licon daSource='devicon-visualstudio-plain' name='Visual Studio' />
      <Licon daSource='devicon-gimp-plain' name='Gimp' />
      <LiconImg daSource={CR} name='Crystal Reports' />
      <LiconText name='SSAS' />
      <LiconText name='SSIS' />
      <LiconText name='SSRS' />
    </SkillItems>
  </Container>
);
};

export default Skills;