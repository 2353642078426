import jsPDF from 'jspdf';
import logoImg from '../assets/images/jkslogo.jpg';

export const calculateTotalValue = (answers) => {
    // Define the base price
    let totalValue = 0;
    let price = 0;
    const data = [];
    let service = '';
    let description = '';
    let total = ''; // Update quantity for question 5

    // What is the primary purpose of your website?
    switch (answers[0]) {
      case "Informational":
        price = 800; // Example price for "Informational"
        break;
      case "E-commerce":
        price = 1000; // Example price for "E-commerce"
        break;
      case "Portfolio":
        price = 800; // Example price for "Portfolio"
        break;
      case "Blog":
        price = 800; // Example price for "Blog"
        break;
      case "Educational":
        price = 1000; // Example price for "Educational"
        break;
      case "Entertainment":
        price = 1000; // Example price for "Entertainment"
        break;
      case "Community":
        price = 800; // Example price for "Community"
        break;
      case "Business":
        price = 1000; // Example price for "Business"
        break;
      case "Non-profit":
        price = 1000; // Example price for "Non-profit"
        break;
      case "Personal":
        price = 800; // Example price for "Personal"
        break;
      case "Other":
        price = 1000; // Example price for "Other"
        break;
      // Add more cases for other answer options
      default:
        price = 0; // Default value if no match found
    }
    totalValue += price;
    service = 'Website';
    description = `${answers[0]}`;
    total = `$${price}.00`; // Example unit cost
    data.push([service, description, total]);

    // Do you have specific design preferences or brand guidelines?
    switch (answers[3]) {
      case "Yes":
        price = 100; // Example price for "specific design"
        description = 'Specific design preferences';
        break;
        default:
        price = 200; // Example price for "no design"
        description = 'No design preferences';
        break;
    }

    totalValue += price;
    service = 'Customization';
    total = `$${price}.00`; // Example unit cost
    data.push([service, description, total]);

    //How many pages do you anticipate the website will have?
    switch (answers[4]) {
      case "1to4":
        price = 100; // Example price for "1to4"
        description = '1 to 4';
        break;
      case "5to8":
        price = 150; // Example price for "5to8"
        description = '5 to 8';
        break;
      case "9to12":
        price = 200; // Example price for "9to12"
        description = '9 to 12';
        break;
      case "13to16":
        price = 250; // Example price for "13to16"
        description = '13 to 16';
        break;
      case "17to20":
        price = 300; // Example price for "17to20"
        description = '17 to 20';
        break;
      default:
        price = 100; // Default value if no match found
        description = '1 to 4';
    }

    totalValue += price;
    service = 'Creating Pages';
    total = `$${price}.00`; // Example unit cost
    data.push([service, description, total]);

    //Do you require any special features or functionalities?
    switch (answers[5]) {
      case "Yes":
        price = 200; // Example price for "Special Features"
        description = 'Special features/ functionalities';
        break;
      default:
        price = 0; // Default value if no match found
        description = 'None';
    }
    totalValue += price;
    service = 'Additional Functions';
    total = `$${price}.00`; // Example unit cost
    data.push([service, description, total]);

    //Will you need the ability to update the website content frequently?
    switch (answers[6]) {
      case "Yes":
        price = 100; // Example price for "Training"
        break;
      default:
        price = 0; // Default value if no match found
    }
    totalValue += price;
    service = 'Providing Training';
    description = answers[6];
    total = `$${price}.00`; // Example unit cost
    data.push([service, description, total]);

    //Do you have any existing website content or materials?
    switch (answers[7]) {
      case "Yes":
        price= 0; // Example price for "Less work"
        break;
      default:
        price= 100; // Default value if no match found
    }
    totalValue += price;
    service = 'Material Provided';
    description = answers[7];
    total = `$${price}.00`; // Example unit cost
    data.push([service, description, total]);
    // Return the total price value
    return {
              array: data,
              total: totalValue
           };
  };

  export const generatePDF = ( businessName, fname, lname, email, results ) => {

    const doc = new jsPDF();

    // Add logo to the PDF
    const logoData = logoImg; // Replace Logo with your logo data or import
    doc.addImage(logoData, 'JPG', 10, 10, 50, 45); // Adjust position and dimensions as needed

    doc.setFont('Roboto'); // Set font name

    doc.setFontSize(12); // Set font size
    doc.text('This quote is valid for the next 30 days, after which values may be subject to change.', 15, 280);

    //bottom total
    doc.setFontSize(14); // Set font size
    doc.text('Total', 155, 272);
    doc.text(`$${results.total}.00`, 175, 272);
    
    //header
    doc.text('JK and Sons Services | Toronto Ontario, Canada', 70, 20);  
    doc.text('jkandsons246@gmail.com | www.johnking.dev', 70, 25);

    //customer data
    doc.text(`${fname} ${lname}`, 10, 82);
    doc.text(`${email}`, 10, 88);
    doc.text('Sent On', 108, 62);
    const currentDate = new Date().toISOString().split('T')[0];
    doc.text(`${currentDate}`, 170, 62);

    //customer header and business name
    doc.setFont('Roboto', 'bold');
    doc.text('RECIPENT:', 10, 65);
    doc.setFontSize(16); // Set font size
    doc.text(`${businessName}`, 10, 75);

    // Draw a colored rectangle
    doc.setFillColor(212, 78, 0); // Set fill color (red in this example)
    //first bar
    doc.rect(100, 40, 100, 15, 'F'); // Draw rectangle with fill
    //second bar
    doc.rect(100, 65, 100, 10, 'F'); // Draw rectangle with fill
    //third bar
    doc.rect(10, 95, 190, 10, 'F'); // Draw rectangle with fill

    //last bar with total
    doc.rect(170, 265, 30, 10); // Draw rectangle border

    // Set text properties
    doc.setTextColor(255, 255, 255); // Set text color (white in this example)

    //titles for each bar
    doc.text('Website Price Quote', 108, 50);
    doc.text('Total', 108, 72);
    doc.text(`$${results.total}.00`, 170, 72);
    doc.setFontSize(14); // Set font size
    doc.text('Service/Product', 15, 102);
    doc.text('Description', 70, 102);
    doc.text('Total', 180, 102);

    doc.setTextColor(0, 0, 0); // Set text color (white in this example)
    let yPos = 111;

    doc.setFont('Roboto', 'normal');
    console.log(results.array);

    results.array.forEach((value, index) => {
      const [service, description, total] = value;

      doc.text(`${service}`, 15, yPos);
      doc.text(`${description}`, 70, yPos);
      doc.text(`${total}`, 178, yPos);
      
      yPos += 4; // Add space between question-answer pairs
      doc.setDrawColor(192, 192, 192); // Set stroke color to gray
      doc.line(10, yPos, 200, yPos); // Draw a line
      yPos += 6;
    });

    yPos += 10;
    doc.setFont('Roboto', 'italic'); // Set font to Roboto italic
    doc.text('*(1) Free logo included', 15, yPos);

    // Convert the PDF to a data URI
    const pdfDataUri = doc.output('datauristring');

    // Extract base64 encoded data from data URI
    const pdfData = pdfDataUri.split(';base64,').pop(); 

    return pdfData;
};