import styled from 'styled-components';

const LangText = styled.p`
  color: #fff;
  background-color: #d44e00;   
  border-radius: 5px 0;
  text-align: center;
  padding: 0 5px 0 5px;
  font-size: .8em;
  font-weight: 700;
  @media (max-width: 600px) {
    font-size: .6em; 
  }
`;


const IconText = ({name}) => {
  return (
        <LangText>{name}</LangText>
  );
}

export default IconText;