import styled from 'styled-components';

const ContainerBox = styled.div`
  display: inline-block;
  margin: 25px 20px;
  padding-top: 10px;
  width: 140px;
  height: 140px;
  background: #ececec;
  border-radius: 5px;
  text-align: center;
  font-family: 'Bruno Ace SC', cursive; 
  box-shadow: 10px 10px 5px #444;
  &:hover {
    box-shadow: 10px 10px 5px #d44e00;
  }
  @media (max-width: 600px) {
    width: 100px;
    height: 100px;  
    margin: 20px 10px;
  }
`;
const Lang = styled.div`
    background-color: #d44e00;    
    width: 90px; 
    height: 90px;
    margin: auto;    
    @media (max-width: 600px) {
      width: 60px; 
      height: 60px;
    }
`;
const LangText = styled.p`
  color: #fff;
  padding-top: 40%;
  font-size: 1em;
  font-weight: 700;
  @media (max-width: 600px) {
    font-size: .8em; 
  }
`;
const Title = styled.p`
  font-size: 1em;
  height: 38px;
  @media (max-width: 600px) {
    font-size: .8em; 
  }
`;

const LiconText = ({name}) => {
  return (
    <ContainerBox>
      <Lang>
        <LangText>{name}</LangText>
      </Lang>
      <Title>{name}</Title>
    </ContainerBox>
  );
}

export default LiconText;