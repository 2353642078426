import React from 'react';
import styled from 'styled-components';

const ProgressContainer = styled.div`
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 5px;
`;

const ProgressBar = styled.div`
  width: ${({ currentStep, totalSteps }) => `${(currentStep / totalSteps) * 100}%`};
  height: 15px;
  background-color: #d44e00;
  border-radius: 5px;
`;

const Percentage = styled.span`
  position: absolute;
  top: 150px;
  left: 50%;
  font-weight: 600;
  transform: translate(-50%, -50%);
  color: #d44e00;
  @media(max-width: 480px) {    
    top: 40%;
  }
`;

const ProgressBarComponent = ({ currentStep, totalSteps }) => {
  const percentage = Math.round((currentStep / totalSteps) * 100);

  return (
    <ProgressContainer>
      <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      <Percentage>{percentage}%</Percentage>
    </ProgressContainer>
  );
};

export default ProgressBarComponent;
