import styled from 'styled-components';
import WarSimVideo from '../../assets/videos/warsim.mp4';
import github from '../../assets/images/github.png';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const VidContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

`;
const WSVid = styled.video`
  margin: auto;
  width: 50%;
  @media(max-width: 600px) {
    width: 100%;
  }
`;
const Heading = styled.div`
  font-family: 'Bruno Ace Sc', cursive;
  font-size: 1.1em;
  font-weight: 900;
  margin-left: 20px;
  margin-top: 10px;
`;
const PContainer = styled.ul`
  font-size: 1.1em;
  margin: 20px;
  line-height: 1.8;
  text-indent: 10px;
  list-style-position: inside;
`;  
const Paragraph = styled.p`
  font-size: 1.1em;
  padding: 30px;
  line-height: 1.8;
  text-indent: 30px;
`;    
const Link = styled.img`
  float: right;
  width: 48px;
  margin: 5px;
  border-radius: 50%;
  box-shadow: 5px 5px 5px #444;
  background-image: radial-gradient(#d44e00, #d44e00, #fff,#fff);
  &:hover {
    background-image: none; 
    box-shadow: 5px 5px 5px #d44e00;       
  }
`;
const WarSim = () => {
  return (
    <Container>      
      <Heading>About The Project</Heading>
      <Paragraph>Immerse yourself in an engaging one-player game that vividly simulates epic battles among Humans, Elves, and Orcs as they vie for dominance over the world. </Paragraph>
      <Heading>The Problem</Heading>
      <Paragraph>Given a two-week time frame and utilizing Java and the terminal, I undertook the challenge of completing a game called Warsim. A skeleton game was provided, and my task involved implementing various options, among which I selected one. Additionally, I incorporated captivating animations to enhance the visual experience for users while they engage with the game.</Paragraph>
      <VidContainer>
        <WSVid autoPlay muted loop>
          <source src={WarSimVideo} type="video/mp4" />
        </WSVid>
      </VidContainer>
      <Heading>The Solution</Heading>
      <Paragraph>
        By harnessing the power of ASCII characters, I commenced the design process by crafting three distinctive characters: Human, Elf, and Orc. Subsequently, I conceptualized three types of armors: chainmail, platemail, and leather. Expanding further, I created a variety of weapons and ammunition, including Sword, Spear, Axe, and Bow with Arrows. The Bow employed arrows as projectiles, while the other weapons involved physical proximity, requiring the character to engage with the next character (enemy) to inflict damage.
      </Paragraph>
      <PContainer>To provide an immersive experience, I crafted diverse arena settings:
          <li>Windy arena featured the presence of birds and clouds.</li>
          <li>Cloudy arena enveloped the scene with clouds alone.</li>
          <li>Rainy arena portrayed heavy clouds and falling rain.</li>
          <li>Sunny arena radiated with the presence of the sun and birds, evoking a sense of warmth and brightness.</li>
      </PContainer>
      <PContainer>
          In addition, the game incorporated several other functions, such as:        
          <li>Saving and resuming gameplay from saved files.</li>
          <li>Implementing a health bar to track the vitality of each player.</li>
          <li>Displaying comprehensive stats for every player, offering insights into their abilities and attributes.</li>
      </PContainer>
      <a href="https://github.com/jksapps246/warsim" target="_blank" rel="noreferrer">
        <Link src={github} alt="Github Icon" />
      </a>
    </Container>
  );
};
    
export default WarSim;