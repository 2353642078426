import styled from 'styled-components';

const Paragraph = styled.p`
  font-size: 1.1em;
  margin: 20px;
  line-height: 1.8;
  text-indent: 30px; 
`;

const Heading = styled.h1`
  font-size: '2em';
  font-family: 'Bruno Ace SC', cursive;
`;
const AboutMe = () => {
  return (
    <div>
      <Heading>Where did it all start John?</Heading>
      <Paragraph>My programming journey began in Barbados, my hometown, and has since continued in Canada. My passion for creation and automation has been a driving force in my journey. Initially, I delved into SQL and crystal reports and spent over eight years building reports for more than 150 clients. However, after relocating to Canada, I taught myself website development and have been exploring that field ever since.</Paragraph>
      <Paragraph>During my pursuit of professional development, I enrolled in the Web and Mobile Development course at triOS College. The program equipped me with a broad range of skills, including proficiency in Java, C#, and ASP.NET. I also received training in Adobe Photoshop and Adobe Illustrator, along with a deeper understanding of JavaScript and expertise in both Android and iPhone development.
        Moreover, I honed my skills in HTML, CSS, Node.js, React, Ubuntu, jQuery, and PHP, which has enabled me to develop web and mobile applications with ease. The program's extensive curriculum helped me to enhance my abilities, and I am proud of the comprehensive knowledge and expertise that I gained from it.</Paragraph>        
    </div>
  );
};

export default AboutMe;
