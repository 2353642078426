import styled from 'styled-components';
import Social from '../components/Social';
import { Link } from 'react-router-dom';
import BkgImg from '../assets/images/background_cover.png';
import logoImg from '../assets/images/logo.png';

const Nav = styled.nav`
  display: flex;
  //justify-content: space-between; /* Aligns items with space between */
  //align-items: center; /* Centers items vertically */
  //position: fixed;

  width: 100%;
  z-index: 2;
  height: 70px;
  padding-top: 20px;
  background-image: linear-gradient(to bottom, #d44e00, transparent);

  @media(max-width: 480px) {
    flex-flow: column wrap;
    height: 300px;
    padding: 0;
  }
`;
const LogoContainer = styled.div`
  flex: 1;
  text-align: left;
  @media(max-width: 480px) {
    text-align: center;
    width: 100%;
    order: 2;
  }
`
const LogoLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  width: 130px;
  height: 130px;
  margin-left: 20px;
  z-index: 2;
`;

const SocialContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  @media(max-width: 480px) {
    width: 100%;
    order: 1;
    padding-top: 20px;
  }
`
const ButtonContainer = styled.div`
  flex: 1;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media(max-width: 480px) {
    justify-content: center;
    text-align: center;
    width: 100%;
    order: 3;
  }
`
const PricingButton = styled(Link)`
  display: block;  
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 10px 20px;
  margin-right: 10px;
  width: 200px;
  font-family: 'Bruno Ace SC', cursive;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;  

  &:hover {
    background-color: #000;
    color: #fff;
    border: 1px solid #fff;
  }
`;
const Image = styled.img`
    background-image: url(${BkgImg});
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 130px;
    height: 130px;
    margin-left: 20px;
    @media(max-width: 480px) {
      margin: 0;
    }
  `; 
const Navigation = () => {
  return (
    <Nav>
      <LogoContainer>
        <LogoLink to="/"> 
          <Image src={logoImg} alt='Logo' />  
        </LogoLink> 
      </LogoContainer>
      <SocialContainer>
        <Social />
      </SocialContainer>
      <ButtonContainer>
        <PricingButton to="/pricing">Website Pricing</PricingButton>
      </ButtonContainer>      
    </Nav>
  );
};

export default Navigation;