import React, { useState, useRef, useEffect } from 'react';
import ProgressBar from '../components/ProgressBarComponent'; // Assume ProgressBar component is implemented separately
import styled from 'styled-components';
import axios from 'axios';
import { calculateTotalValue, generatePDF } from '../components/Functions';

const Container = styled.div`
    //width: 100%;
    max-width: 660px;
    padding: 50px;
    border-radius: 5px;
    background-color: #fff;
    //margin: 40px 20px 20px 20px;
    margin: 40px auto;
  `;

  const Input = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 1em;
`;
const Label = styled.label`
  font-size: 1em;
  margin-right: 20px;
  font-family: 'Bruno Ace SC', cursive;
`;
const Heading = styled.h1`
  font-size: '2em';
  font-family: 'Bruno Ace SC', cursive;
  margin-bottom: 20px;
`;
const Heading2 = styled.div`
  font-family: 'Bruno Ace Sc', cursive;
  font-size: 1.1em;
  font-weight: 900;
  margin-left: 20px;
  margin-top: 10px;
`;
const Heading3 = styled.span`
  font-family: 'Bruno Ace Sc', cursive;
  font-size: .9em;
  font-weight: 600;
  margin-left: 20px;
  margin-right: 20px;

`;
const Select = styled.select`
  width: 100%;
  padding: 12px 20px;
  margin: 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 1em;
`;

const Paragraph = styled.p`
  width: 100%;
  font-size: 1.1em;
  margin: 20px;
  line-height: 1.8;
  //text-align: center;
`;

const Paragraph2 = styled.p`
  font-size: 1.1em;
  margin: 5px;

`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 150px;
  max-width: 250px;
  height: 40px;
  color: #000;
  font-size: 1em;
  font-weight: 700;
  background: #ececec;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: 'Bruno Ace SC', cursive; 
  box-shadow: 10px 10px 5px #444;
  cursor: pointer;
  margin: 0 auto; /* Horizontally center the button */
  
  &:hover {
    box-shadow: 10px 10px 5px #d44e00;
  }
`;

const WebsiteQuestionnaire = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(Array(12).fill(''));
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [showPriceForm, setShowPriceForm] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [BusinessName, setBusinessName] = useState('');
  const [Fname, setFirstName] = useState('');
  const [Lname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const formRef = useRef(null); // Reference to the form element
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (showEmailForm && formRef.current) {
      if (!formRef.current) return;
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showEmailForm]);

  const questions = [
    {
      question: "What is the primary purpose of your website?",
      answers: [
        { input:  <Select value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })}>
                    <option value="">Select</option>
                    <option value="Informational">Informational</option>
                    <option value="E-commerce">E-commerce</option>
                    <option value="Portfolio">Portfolio</option>
                    <option value="Blog">Blog</option>
                    <option value="Educational">Educational</option>
                    <option value="Entertainment">Entertainment</option>
                    <option value="Community">Community</option>
                    <option value="Business">Business</option>
                    <option value="Non-profit">Non-profit</option>
                    <option value="Personal">Personal</option>
                    <option value="Other">Other</option>
                  </Select>
        },
      ]
    },
    {
      question: "Who is your target audience?",
      answers: [
        { label:  <Label>Age group</Label>, 
          input:  <Select value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })}>
                    <option value="">Select</option>
                    <option value="under18">Under 18</option>
                    <option value="18to24">18 - 24</option>
                    <option value="25to34">25 - 34</option>
                    <option value="35to44">35 - 44</option>
                    <option value="45to54">45 - 54</option>
                    <option value="55to64">55 - 64</option>
                    <option value="65plus">65+</option>
                  </Select>
        },
      ]
    },
    {
      question: "What is your target audience's interest?",
      answers: [
        { 
          label:  <Label>Interests</Label>, 
          input:  <Select value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })}>
                    <option value="">Select</option>
                    <option value="sports">Sports</option>
                    <option value="music">Music</option>
                    <option value="travel">Travel</option>
                    <option value="cooking">Cooking</option>
                    <option value="fashion">Fashion</option>
                    <option value="technology">Technology</option>
                    <option value="health">Health</option>
                    <option value="fitness">Fitness</option>
                    <option value="arts">Arts</option>
                    <option value="culture">Culture</option>
                    <option value="education">Education</option>
                    <option value="business">Business</option>
                    <option value="science">Science</option>
                    <option value="environment">Environment</option>
                    <option value="politics">Politics</option>
                    <option value="philanthropy">Philanthropy</option>
                    <option value="religion">Religion</option>
                    <option value="other">Other</option>
                  </Select>
        }
      ]
    },
    {
      question: "Do you have specific design preferences or brand guidelines?",
      answers: [
        { input:  <Select value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })}>
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Select>
        }
      ]
    },
    {
      question: "How many pages do you anticipate the website will have?",
      answers: [
        { input:  <Select value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })}>
                    <option value="">Select</option>
                    <option value="1to4">1 - 4</option>
                    <option value="5to8">5 - 8</option>
                    <option value="9to12">9 - 12</option>
                    <option value="13to16">13 - 16</option>
                    <option value="17to20">17 - 20</option>
                  </Select>
        }
      ]
    },
    {
      question: "Do you require any special features or functionalities?",
      answers: [
        { input:  <Select value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })}>
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Select>
        }
      ]
    },
    {
      question: "Will you need the ability to update the website content frequently?",
      answers: [
        { input:  <Select value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })}>
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Select>
        }
      ]
    },
    {
      question: "Do you have any existing website content or materials?",
      answers: [
        { input:  <Select value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })}>
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Select>
        }
      ]
    },
    {
      question: "Are there any websites you admire or want to emulate?",
      answers: [
        { label: <Label>Website</Label>, 
          input: <Input type="text" placeholder="e.g www.johnking.dev" value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })} /> 
        }
      ]
    },
    {
      question: "Do you have a preferred platform or CMS for your website?",
      answers: [
        { label: <Label>Platform name</Label>, 
          input: <Input type="text" placeholder="e.g Wordpress" value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })} /> 
        }
      ]
    },
    {
      question: "What is your budget for the project?",
      answers: [
        { 
          label: <Label>Enter Dollar Value($)</Label>,
          input: <Input type="number" min="500" placeholder="5000" value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })} />},
      ]
    },
    {
      question: "What is your timeline for the project?",
      answers: [
        { 
          label: <Label>Number of weeks</Label>,
          input: <Input type="number" min="3" placeholder="3" value={answers[currentQuestion]} onChange={(e) => setAnswers({ ...answers, [currentQuestion]: e.target.value })} />}
      ]
    },
    // Add more questions with custom answer options as needed
  ];

  const handleNext = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrevious = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  const handleQuestionSubmit = () => {
    setShowQuestionForm(true);
  };

  const handleRestart = () => {
    setAnswers(Array(12).fill(''));
    setCurrentQuestion(0);
    setShowQuestionForm(false);
    setShowEmailForm(false);
    setShowPriceForm(false);
    setBusinessName('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
  };

  const handleGetPrices = () => {
    setShowEmailForm(true);
  };

  const handlePriceFormSubmit = (e) => {
    e.preventDefault();
      //get Totals and Pricing Data    
    const results = calculateTotalValue(answers);
    //get pdf
    const pdfData = generatePDF(BusinessName, Fname, Lname, email, results);  
  
    //get formData
    const formData = {
      businessName: BusinessName,
      fname: Fname,
      lname: Lname,
      email: email,
      pdfDataUri: pdfData, // Extract base64 encoded data from data URI
      //token: token // value obtained from reCAPTCHA
    };

    axios({
      method: 'POST',
      url: './pricing.php',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: formData
    })
      .then(response => { 
            setShowEmailForm(false); // Close email form
            setShowPriceForm(true); // Display the results           
            setMessage(response.data);
          })
      .catch(error => {
        setMessage('An error occurred while sending the email.');
        console.log(error);
      });

  };

  return (
    <div>
      <Container>
        {!showQuestionForm ? (
          <>
            <ProgressBar currentStep={currentQuestion + 1} totalSteps={questions.length} />
            <Heading>Website Questionaire</Heading>
            <Heading2>Question {currentQuestion + 1}</Heading2>
            <Paragraph>{questions[currentQuestion].question}</Paragraph>

            <div>
              {questions[currentQuestion].answers.map((answer, index) => (
                <div key={index}>
                  <label>{answer.label}</label>
                  {answer.input}
                </div>
              ))}
            </div>
            <ButtonContainer>
              {currentQuestion > 0 && <Button onClick={handlePrevious}>Previous</Button>}
              {currentQuestion < questions.length - 1 && answers[currentQuestion] && (
                <Button onClick={handleNext}>Next</Button>
              )}
              {currentQuestion === questions.length - 1 && answers[currentQuestion] && (
                <Button onClick={handleQuestionSubmit}>Submit</Button>
              )}
            </ButtonContainer>
          </>
        ) : (
          <>
            <Heading>Summary of Answers</Heading>
            {questions.map((question, index) => (
              <div key={index}>
                <Paragraph2>{question.question}</Paragraph2>
                <Paragraph2><Heading3>Answer:</Heading3> {answers[index]}</Paragraph2>
                <hr />
              </div>
            ))}
            <ButtonContainer>
              <Button onClick={handleRestart}>Restart</Button>
              <Button onClick={handleGetPrices}>Get Prices</Button>
            </ButtonContainer>
          </>
        )}
      </Container>
      {showEmailForm && (
        <Container ref={formRef}>
          <Heading>Enter Your Information</Heading>
          <Paragraph>Upon providing the aforementioned details, we will generate and send an estimated price quote to the email address provided below.</Paragraph>
          <form onSubmit={handlePriceFormSubmit}>
            <Label htmlFor="BusinessName">Business Name:</Label>
            <Input type="text" id="BusinessName" value={BusinessName} onChange={(e) => setBusinessName(e.target.value)} required />

            <Label htmlFor="Fname">First Name:</Label>
            <Input type="text" id="Fname" value={Fname} onChange={(e) => setFirstName(e.target.value)}  required />

            <Label htmlFor="Lname">Last Name:</Label>
            <Input type="text" id="Lname" value={Lname} onChange={(e) => setLastName(e.target.value)}  required />

            <Label htmlFor="email">Email:</Label>
            <Input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)}  required />
            <ButtonContainer>
              {/* <Button className="g-recaptcha"
                id="contact_button" 
                data-sitekey="6LdJaLMpAAAAAAVq4ZOD-gOqWygkFBu2HEE1kelD"
                type="submit">Send
              </Button> */}
              <Button type="submit">Send</Button>
              {message && <p style={{ color: 'red'}}>{message}</p>}
            </ButtonContainer>
          </form>
        </Container>
      )}
      {showPriceForm && (
          <Container>
            <Paragraph>
              {message && <p style={{ color: 'green', fontWeight: 'bold' }}>{message}</p>}
            </Paragraph>
            <ButtonContainer>
              <Button onClick={handleRestart}>Restart</Button>
            </ButtonContainer>
          </Container>
      )}
    </div>
  );
};

export default WebsiteQuestionnaire;
