import React from "react";
import styled from "styled-components";
import Navbar from "./components/Navigation";
import Footer from "./components/Foot";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/Home";
import Pricing from "./pages/WebsiteQuestionnaire";

export const Main = styled.main`
  min-height: calc(100vh - 150px);
  //min-width: 100vh;
`;
function App() {
  return (
    <Router>
      <Navbar />
      <Main>
        <Routes>        
          <Route exact path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
        </Routes>
      </Main>
      <Footer />
    </Router>
  );
}
 
export default App;
