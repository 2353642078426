import React, { useState } from 'react';
import styled from 'styled-components';
import Icollect from './projects/ICollect';
import MechConnect from './projects/MechanicConnect';
import Logos from './projects/Logos';
import WarSim from './projects/WarSim';
import BashScript from './projects/BashScript';
import MovieJest from './projects/websites/MovieJest';
import AAC from './projects/websites/AAC';
import IconText from './projects/IconText';


const Container = styled.div`
  height: ${({ expanded }) => (expanded ? 'auto' : '45px')};
  overflow: hidden;
  transition: height 0.5s;
  width: auto;
  background-color: #ebe9e9;
  box-shadow: 5px 5px 5px #444;
  margin: 10px 10px 10px 25px;
  padding: 10px;
  border-radius: 5px;
  @media(max-width: 600px){
    margin: 5px 5px 5px 10px;
  }
`;
const Heading = styled.div`
font-family: 'Bruno Ace Sc', cursive;
font-size: 2em;
margin-left: 10px;
font-weight: 700;
`;
const SubHeading = styled.div`
display: flex;
font-family: 'Bruno Ace Sc', cursive;
font-size: 1.2em;
margin-left: 10px;
@media(max-width: 600px){
  font-size: .9em;
}
`;
const Button = styled.button`
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1em;
  &:hover {
    color: #d44e00;
  }
`;
const Icons = styled.div`
display: flex;
align-self: flex-end;
padding: 0 5px 0 5px;
gap: 2px;
@media (max-width: 600px) {
 visibility: hidden;
}
`;
const ExpandableButton = ({ onToggle, label }) => {
  return (
    <Button onClick={onToggle}>
      {label}
    </Button>
  );
};
const Projects = () => {
  const [expandedSections, setExpandedSections] = useState({ 
    icollect: false, 
    logos: false,
    mechanicconnect: false,
    warsim: false,
    bash: false,
    moviejest: false,
    aac: false
  });

  const toggleContent = (section) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [section]: !prevExpandedSections[section],
    }));
  }; 
 
  return (
    <div>
      <div>
        <Heading>Websites</Heading>
        <p>(Click {'\u25b2'} to view the information)</p>
        <Container expanded={expandedSections.moviejest}>
          <SubHeading>
            <ExpandableButton 
              onToggle={() => toggleContent('moviejest')}
              label={expandedSections.moviejest ? '\u25bc' : '\u25b2'}
            /> 
            Moviejest.ca
            <Icons>
              <IconText name='php' />
              <IconText name='html' />
              <IconText name='css' />
              <IconText name='javaScript' />
            </Icons>
          </SubHeading>
          <hr />
          <MovieJest />
        </Container>
        <Container expanded={expandedSections.aac}>
          <SubHeading>
            <ExpandableButton 
              onToggle={() => toggleContent('aac')}
              label={expandedSections.aac ? '\u25bc' : '\u25b2'}
            /> 
            abstractartconcoure.com
            <Icons>
              <IconText name='php' />
              <IconText name='wordpress' />
            </Icons>
          </SubHeading>            
          <hr />
          <AAC />
        </Container>
      </div>
        <Heading>Apps</Heading>
      <div>
        <Container expanded={expandedSections.mechanicconnect}>
          <SubHeading>
            <ExpandableButton 
                onToggle={() => toggleContent('mechanicconnect')}
                label={expandedSections.mechanicconnect ? '\u25bc' : '\u25b2'}
            /> 
            MechanicConnect
            <Icons>
              <IconText name='android' />
              <IconText name='kotlin' />
              <IconText name='gimp' />
              <IconText name='sql' />
            </Icons>
          </SubHeading>
          <hr />
          <MechConnect />
        </Container>
        <Container expanded={expandedSections.icollect}>
          <SubHeading> 
            <ExpandableButton 
              onToggle={() => toggleContent('icollect')}
              label={expandedSections.icollect ? '\u25bc' : '\u25b2'}
            />                  
            iCollect 
            <Icons>
              <IconText name='iphone' />
              <IconText name='xcode' />
              <IconText name='swift' />
              <IconText name='sql' />
            </Icons>
          </SubHeading>
          <hr />
          <Icollect />
        </Container>
      </div>
        <Heading>Games</Heading>
      <div>
        <Container expanded={expandedSections.warsim}>
        <SubHeading>
          <ExpandableButton 
            onToggle={() => toggleContent('warsim')}
            label={expandedSections.warsim ? '\u25bc' : '\u25b2'}
          /> 
          WarSim
          <Icons>
              <IconText name='java' />
              <IconText name='animation' />
              <IconText name='ascii' />
            </Icons>
        </SubHeading>
        <hr />
        <WarSim />
        </Container>
      </div>
      <Heading>Graphics</Heading>
      <div>
        <Container expanded={expandedSections.logos}>
          <SubHeading>
            <ExpandableButton 
              onToggle={() => toggleContent('logos')}
              label={expandedSections.logos ? '\u25bc' : '\u25b2'}
            />   
            Logos
            <Icons>
              <IconText name='photoshop' />
              <IconText name='gimp' />
            </Icons>
          </SubHeading>
          <hr />
          <Logos />
        </Container>
      </div>
      <Heading>Other</Heading>
      <div>
        <Container expanded={expandedSections.bash}>
          <SubHeading>
            <ExpandableButton 
              onToggle={() => toggleContent('bash')}
              label={expandedSections.bash ? '\u25bc' : '\u25b2'}
            />   
            BashScript
            <Icons>
              <IconText name='linux' />
              <IconText name='script' />
              <IconText name='html' />
              <IconText name='css' />
            </Icons>
          </SubHeading>
          <hr />
          <BashScript />
        </Container>
      </div>
    </div>
  );
}

export default Projects;