import React, { useState } from 'react';
import styled from 'styled-components';
import wlink from '../../../assets/images/link.png';
import AACImg from '../../../assets/images/websites/aac.png';

const Paragraph = styled.p`
  font-size: 1.1em;
  margin: 20px;
  line-height: 1.8;
  text-indent: 30px;
`;  
const Heading = styled.div`
  font-family: 'Bruno Ace Sc', cursive;
  font-size: 1.1em;
  font-weight: 900;
  margin-left: 20px;
  margin-top: 10px;
`;
const Link = styled.img`
  float: right;
  width: 48px;
  margin: 5px;
  border-radius: 50%;
  box-shadow: 5px 5px 5px #444;
  background-image: radial-gradient(#d44e00, #d44e00, #fff,#fff);
  &:hover {
    background-image: none; 
    box-shadow: 5px 5px 5px #d44e00;       
  }
`;
const Image = styled.img`
  width: 50%;
  heigth: 200px;
  margin: 10px;
  cursor: zoom-in;
  border-radius: 5px;
  box-shadow: 10px 10px 5px #444; 
  ${(props) =>
  props.expanded &&
  `transform: scale(1.2);
  cursor: zoom-out;
  `
  }
  &:hover {
  box-shadow: 10px 10px 5px #d44e00;        
  }
  @media(max-width: 600px) {
  width: 60%;
  heigth: 150px;
  margin: auto;
  }
`;
const ImageContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: center; 
`;
const AAC = () => {

  const [expandedImage, setExpandedImage] = useState(null);      
    const handleImageClick = (imageId) => {
      if (expandedImage === imageId) {
        setExpandedImage(null);
      } else {
        setExpandedImage(imageId);
      }
    };

  return (
    <div>      
      <ImageContainer>
        <Image 
          src={AACImg} 
          alt='Abstract Art Counture'
          expanded={expandedImage === AACImg}
          onClick={() => handleImageClick(AACImg)}
        />   
      </ImageContainer>
      <Heading>About The Project</Heading>
      <Paragraph>
      Employing WordPress as the platform of choice, I crafted an e-commerce website for a client seeking a straightforward online presence to showcase and sell her exquisite paintings. The website seamlessly integrates an intuitive interface, allowing visitors to explore and purchase artwork with ease.
      </Paragraph>         
      <a href="https://abstractartcouture.com/" target="_blank" rel="noreferrer">
        <Link src={wlink} alt="Link Icon" />
      </a>
    </div>
  );
}

export default AAC;