import styled from 'styled-components';
import React, { useState } from 'react';
import axios from 'axios';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  @media(max-width: 600px){
    gap: 1px;
  }
`;

const Paragraph = styled.p`
  width: 100%;
  font-size: 1.1em;
  margin: 20px;
  line-height: 1.8;
  text-align: center;
`;

const Label = styled.label`
  font-size: 1em;
  margin-right: 20px;
  font-family: 'Bruno Ace SC', cursive;
`;

const Heading = styled.h1`
  font-size: 2em; /* Removed quotes from value */
  font-family: 'Bruno Ace SC', cursive;
  margin-bottom: 20px;
`;

const Form = styled.form`
  border: 3px solid #f1f1f1;
  background: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 3.4rem 0 rgba(0,0,0,0.1);
  margin: 5rem auto 10rem;
  padding: 3rem 4rem;
  width: 50%;
  min-width: 360px;

  @media(max-width: 480px) {
    padding: 2rem 2rem; 
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 12px 20px;
  margin: 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 1em;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 1em;
`;

const TArea = styled.textarea`
  width: 100%;
  padding: 12px 20px;
  margin: 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 1em;
`;

const Button = styled.button`
  width: 100%;
  max-width: 300px;
  height: 40px;
  color: #000;
  font-size: 1em;
  font-weight: 700;
  background: #ececec;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: 'Bruno Ace SC', cursive; 
  box-shadow: 10px 10px 5px #444;
  position: relative;
  float: right;
  cursor: pointer;
  &:hover {
    box-shadow: 10px 10px 5px #d44e00;
  }
`;

const Button2 = styled.button`
  width: 100%;
  max-width: 300px;
  height: 40px;
  color: #000;
  font-size: 1em;
  font-weight: 700;
  background: #ececec;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-family: 'Bruno Ace SC', cursive; 
  box-shadow: 10px 10px 5px #444;
  cursor: pointer;
  &:hover {
    box-shadow: 10px 10px 5px #d44e00;
  }
`;

const ThankYouMessage = styled.div`
  width: 100%;
  text-align: center;
`;

const Contact = () => {
  const [reason, setReason] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [showForm, setShowForm] = useState(true);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = {
        reason: reason,
        name: name,
        email: email,
        subject: subject,
        description: description,
      };

      axios({
        method: 'post',
        url: './contact.php',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: formData
      })
        .then(response => {
          setShowForm(false);
          setMessage(response.data);
        })
        .catch(error => {
          setMessage('An error occurred while sending the message.');
          console.log(error);
        });
  };
  
  const handleFormReset = () => {
    setShowForm(true);
    setMessage('');
    setReason('');
    setName('');
    setEmail('');
    setSubject('');
    setDescription('');
  };

  return (
    <Container>
      <Heading>How to get in contact with you John?</Heading>
      <Paragraph>
        You can email me at Johnnjking01@gmail.com, message me on <a href="https://linkedin.com/in/john-king01" target="_blank" rel="noreferrer">linkedin</a> or send a message below.
      </Paragraph>         
      {showForm ? (
        <Form method="POST" onSubmit={handleFormSubmit} encType="application/x-www-form-urlencoded">          
          <Heading>Contact Form</Heading>
          <div>
            <Label htmlFor="reason">Reason:</Label>
            <Select name="reason" id="reason" value={reason} onChange={(e) => setReason(e.target.value)} required >
              <option value="">Select a reason</option>
              <option value="Personal">Personal</option>
              <option value="Business">Business</option>
              <option value="Recruitment">Recruitment</option>
            </Select>
          </div>
          <div>
            <Label htmlFor="name">Name:</Label>
            <Input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} required  />
          </div>
          <div>
            <Label htmlFor="email">Email:</Label>
            <Input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div>
            <Label htmlFor="subject">Subject:</Label>
            <Input type="text" name="subject" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} required />
          </div>
          <div>
            <Label htmlFor="description">Description:</Label>
            <TArea id="description" name="description"  rows= "10" value={description} onChange={(e) => setDescription(e.target.value)} required />
          </div>
          <Button type='submit' >Submit</Button>
          {/* <Button className="g-recaptcha"
            id="contact_button" 
            data-callback='handleFormSubmit'
            data-sitekey="6LeMdbMpAAAAAKeM-E_644aeujdzvfBYRW0QUir6"
            data-action='submit'>Send</Button> */}

          {message && <p style={{ color: 'red'}}>{message}</p>}
        </Form>   
      ) : (
        <ThankYouMessage>
          <Paragraph>
            {message && <p style={{ color: 'green', fontWeight: 'bold' }}>{message}</p>}
            <br />
            Go to{' '} <a href="/">Homepage</a> or 
          </Paragraph>
          <Button2 onClick={handleFormReset}>Send Another Message</Button2>          
        </ThankYouMessage>
      )}
    </Container>
  );
};

export default Contact;
