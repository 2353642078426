import styled from 'styled-components';
import heroVideo from '../assets/videos/heroVideo.mp4';
// import Play from './Play';
// import BkgImg from '../assets/images/background_cover.png';
//import logoImg from '../assets/images/logo.png';
const Hero = () => {

  const HeroVid = styled.video` 
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
  `;
  const Container = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    height: 30vh;
    overflow: hidden;
    position: relative;
    width: 100%;

  `;
  const Title = styled.p`
    font-family: 'Bruno Ace Sc', cursive;
    font-size: 3em;
    height: 38px;
    color: #fff;
    @media (max-width: 600px) {
      font-size: 1.5em; 
    }
  `;
  const ImagesContainer= styled.div`
    position: absolute;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;    
  `; 
  // const Image = styled.img`
  //   background-image: url(${BkgImg});
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   display: block;
  //   width: 220px;
  //   height: 220px;
  //   @media (max-width: 1200px) {
  //     display: block;
  //     width: 220px;
  //     height: 220px;
  //   } 
  //   @media (max-width: 600px) {
  //     display: block;
  //     width: 130px;
  //     height: 130px;
  //   } 
  // `; 
  return (
    <Container>
      <ImagesContainer>
        {/* <Image src={logoImg} alt='Logo' />       */}
        <Title>Welcome to my Portfolio</Title>
      </ImagesContainer>
      <HeroVid autoPlay muted loop>
        <source src={heroVideo} type="video/mp4" />
      </HeroVid>
    </Container>
  );
};
  
export default Hero;